import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'antd'
import { EduButton } from '@edulastic/common'

const GoogleAuthRetryModal = ({
  visible,
  onCancel,
  onRetry,
  isHangoutMeet = false,
}) => {
  const hangoutMeetPermissionMsg =
    'To fully integrate with Google Meet, Pear Assessment requires all necessary permissions to be granted.'
  const googleClassroomPermissionMsg =
    'To fully sync with Google Classroom and share assignments and grades, Pear Assessment requires all necessary permissions to be granted.'
  return (
    <StyledModal
      title="Alert"
      visible={visible}
      footer={null}
      closable={false}
      data-cy="retry-model"
      centered
    >
      <p style={{ marginBottom: '40px' }} data-cy="retry-content">
        {isHangoutMeet
          ? hangoutMeetPermissionMsg
          : googleClassroomPermissionMsg}
        <span style={{ display: 'block', marginTop: '20px' }}>
          Please select all the permissions to proceed.
        </span>
      </p>
      <StyledDiv>
        <EduButton
          height="40px"
          width="200px"
          isGhost
          onClick={onCancel}
          data-cy="retry-cancel"
        >
          Cancel
        </EduButton>
        <EduButton
          height="40px"
          width="200px"
          onClick={onRetry}
          data-cy="retry-proceed"
        >
          Try Again
        </EduButton>
      </StyledDiv>
    </StyledModal>
  )
}

GoogleAuthRetryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
}

export default GoogleAuthRetryModal

const StyledDiv = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 20px;
  justify-content: center;
`

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 630px;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-title {
      font-size: 22px;
      line-height: 30px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      padding-left: 24px;
    }
    .ant-modal-body {
      padding: 24px 46px 32px;
      p {
        display: inline
        text-align: left;
        font: 14px / 19px Open Sans;
        font-weight: 600;
        color: #304050;
      }
    }
  }
`
