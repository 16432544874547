import React from 'react'
import Divider from 'antd/lib/divider'
import { IconDeskTopMonitor } from '@edulastic/icons'
import { EduButton, FlexContainer } from '@edulastic/common'
import { views } from '../constants'
import {
  StyledUploadIcon,
  StyledDocUploadTitle,
  StyledDividerContainer,
  StyledORText,
} from '../styled-components'

function QtiWebctUploader({ fileInputClickHandler, view }) {
  return (
    <FlexContainer
      onClick={fileInputClickHandler}
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
    >
      <StyledUploadIcon />
      <StyledDocUploadTitle>
        {view === views.TEST_SELECT_QTI_UPLOAD
          ? 'Drag and drop your QTI file'
          : 'Drag and drop your WebCT zip file'}
      </StyledDocUploadTitle>
      <StyledDividerContainer>
        <Divider />
        <StyledORText>OR</StyledORText>
        <Divider />
      </StyledDividerContainer>
      <FlexContainer>
        <EduButton
          data-cy="uploadFromDeviceButton"
          style={{ borderRadius: '8px' }}
        >
          <IconDeskTopMonitor />
          Upload From Device
        </EduButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default QtiWebctUploader
