import API from '@edulastic/api/src/utils/API'
import qs from 'qs'

import { reportPrefix } from '../common/constants'

const api = new API()

const studentScaledScoreReportPrefix = '/student-scaled-score'

const fetchStudentScaledScoreSummary = (params) =>
  api
    .callApi({
      url: `${reportPrefix}${studentScaledScoreReportPrefix}/summary`,
      method: 'get',
      params,
      paramsSerializer: (param) =>
        qs.stringify(param, { arrayFormat: 'comma' }),
      useSlowApi: true,
    })
    .then((response) => response?.data?.result)

const fetchStudentScaledScoreDomainDetails = (params) =>
  api
    .callApi({
      url: `${reportPrefix}${studentScaledScoreReportPrefix}/domain-details`,
      method: 'get',
      params,
      paramsSerializer: (param) =>
        qs.stringify(param, { arrayFormat: 'comma' }),
      useSlowApi: true,
    })
    .then((response) => response?.data?.result)

export default {
  fetchStudentScaledScoreSummary,
  fetchStudentScaledScoreDomainDetails,
}
