import React from 'react'
import { fadedBlack2, themeColor } from '@edulastic/colors'
import ReactJoyride from 'react-joyride'

const EduJoyride = (props) => {
  const options = {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: themeColor,
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: fadedBlack2,
    width: 246,
    zIndex: 1000,
    ...(props.options || {}),
  }

  const buttonBase = {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    color: themeColor,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    padding: 8,
    WebkitAppearance: 'none',
    outline: 'none',
  }

  return (
    <>
      <ReactJoyride
        styles={{
          options,
          buttonNext: buttonBase,
          buttonBack: {
            ...buttonBase,
            marginRight: '6rem',
          },
          buttonSkip: {
            display: 'none',
          },
          tooltipTitle: {
            fontSize: 14,
            fontWeight: 400,
            textAlign: 'left',
            margin: 0,
          },
          tooltipContent: {
            fontSize: 12,
            fontWeight: 400,
            textAlign: 'left',
            margin: 0,
            padding: 0,
            paddingTop: '8px',
          },
          ...props.styles,
        }}
        disableOverlayClose
        disableCloseOnEsc
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Done',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        {...props}
      />
    </>
  )
}

export default EduJoyride
