import React from 'react'
import {
  IconAIFileAttachment,
  IconRetryCircle,
  IconTrash,
} from '@edulastic/icons'
import { views } from '../constants'
import {
  StyledUploaderContainer,
  StyledFileNameText,
  StyledUploadStatusContainer,
  StyledIconCheck,
  StyledIconWrong,
  StyledUploadStatusText,
  StyledUploadActionContainer,
  StyledActionText,
  StyledFlexItemContainer,
} from '../styled-components'

function UploadStatus({ view, name, uuid, isUploadSuccess, handleDelete }) {
  if (view === views.TEST_SELECT_DOC_UPLOAD) {
    return (
      <StyledUploaderContainer width="70%">
        <IconAIFileAttachment />
        <StyledFileNameText width="197px">{name}</StyledFileNameText>
        <StyledUploadStatusContainer>
          {isUploadSuccess ? (
            <StyledIconCheck />
          ) : (
            <StyledIconWrong fillColor="#E43334" />
          )}
          <StyledUploadStatusText
            data-cy="uploadStatusText"
            isSuccess={isUploadSuccess}
          >
            {isUploadSuccess ? 'Uploaded' : 'Upload Failed'}
          </StyledUploadStatusText>
        </StyledUploadStatusContainer>
        <StyledUploadActionContainer
          data-cy="deleteUploadedFile"
          paddingLeft="30px"
          onClick={() => handleDelete(uuid)}
        >
          <IconRetryCircle />
          <StyledActionText>
            {isUploadSuccess ? 'REPLACE DOC' : 'RETRY'}
          </StyledActionText>
        </StyledUploadActionContainer>
      </StyledUploaderContainer>
    )
  }
  return (
    <StyledUploaderContainer>
      <StyledFlexItemContainer
        flexGrow={view === views.ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS ? 1 : 0}
        marginLeft="10px"
      >
        <IconAIFileAttachment />
      </StyledFlexItemContainer>
      <StyledFileNameText width="197px">{name}</StyledFileNameText>
      <StyledUploadStatusContainer>
        {isUploadSuccess ? (
          <StyledIconCheck />
        ) : (
          <StyledIconWrong fillColor="#E43334" />
        )}
        <StyledUploadStatusText
          data-cy="aiDocUploadStatus"
          isSuccess={isUploadSuccess}
        >
          {isUploadSuccess ? 'Uploaded' : 'Upload Failed'}
        </StyledUploadStatusText>
      </StyledUploadStatusContainer>
      <StyledUploadActionContainer onClick={() => handleDelete(uuid)}>
        {isUploadSuccess ? (
          <IconTrash
            width={11}
            height={14}
            color="#E43334"
            themeColor="#E43334"
          />
        ) : (
          <IconRetryCircle />
        )}
        <StyledActionText
          data-cy="aiDeleteDoc"
          textColor={isUploadSuccess ? '#e43334' : '#11a084'}
        >
          {isUploadSuccess ? 'DELETE' : 'RETRY'}
        </StyledActionText>
      </StyledUploadActionContainer>
    </StyledUploaderContainer>
  )
}

export default UploadStatus
