export const testStatusBackgroundColor = {
  draft: '#F3F3F3',
  published: '#E8F2FF',
  rejected: '#F6C7D5',
  inreview: '#C8F7FF',
}

export const testStatusTextColor = {
  draft: '#979BA1',
  published: '#798CA8',
  rejected: '#CE5A7C',
  inreview: '#51A9B7',
}
