/* eslint-disable react/prop-types */
import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconQuestionCircle = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="16.338"
    height="16.338"
    viewBox="0 0 16.338 16.338"
    {...props}
  >
    <g transform="translate(-0.001 0)">
      <path
        d="M8.061,0a8.169,8.169,0,1,0,8.277,8.059A8.17,8.17,0,0,0,8.061,0ZM8.037,13.142H7.992a1.189,1.189,0,0,1,.028-2.378h.04a1.189,1.189,0,1,1-.023,2.378Zm2.922-5.8a5.4,5.4,0,0,1-.975.873l-.5.344a1.355,1.355,0,0,0-.5.61,1.262,1.262,0,0,0-.077.511v.08H7L7.009,9.6a2.081,2.081,0,0,1,.314-1.373,8.084,8.084,0,0,1,1.42-1.143,1.5,1.5,0,0,0,.336-.344,1.21,1.21,0,0,0,.288-.705,1.375,1.375,0,0,0-.261-.813,1.088,1.088,0,0,0-.942-.354,1.027,1.027,0,0,0-.952.44,1.767,1.767,0,0,0-.288.95v.081H4.962l0-.085A2.983,2.983,0,0,1,6.24,3.681,3.357,3.357,0,0,1,8.087,3.2a3.986,3.986,0,0,1,2.382.686,2.378,2.378,0,0,1,.964,2.052A2.348,2.348,0,0,1,10.959,7.342Z"
        transform="translate(0 0)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconQuestionCircle)
