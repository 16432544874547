import loginBg from '../../../student/assets/bg-login.png'
import greatMindLogo from '../../../student/assets/GM_Horizontal.png'

export const Partners = {
  login: {
    keyName: 'login',
    name: 'login',
    headerLogo:
      '//cdn.edulastic.com/JS/webresources/images/as/as-dashboard-logo.png',
    boxTitle: 'Login',
    background: false, // loginBg, background image has been removed from login page
    position: 'start',
    opacity: 0.5,
  },
  readicheck: {
    keyName: 'readicheck',
    name: 'readicheck',
    headerLogo: '//cdn.edulastic.com/default/ReadiCheckItemBank.png',
    boxTitle: '//cdn.edulastic.com/default/readicheck_logo.png',
    background: '//cdn.edulastic.com/default/readicheck_home-page-bg-1.png',
    colorFilter: 'brightness(100)',
    position: 'center',
    opacity: 0.5,
  },
  greatminds: {
    keyName: 'greatminds',
    name: 'greatMind',
    headerLogo: greatMindLogo,
    boxTitle: 'Login',
    background: '//cdn.edulastic.com/default/Affirm_Background_Image.jpg',
    colorFilter: 'brightness(1)',
    position: 'center',
    opacity: 0.2,
  },
}

export { loginBg }
