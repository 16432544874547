const STANDARD_DROPDOWN_LIMIT_1000 = 1000
const STANDARD_LEVELS = {
  TLO: 'TLO',
  ELO: 'ELO',
  SUB_ELO: 'SUB_ELO',
}
const TLO_LEVELS = [STANDARD_LEVELS.TLO]
const ELO_LEVELS = [STANDARD_LEVELS.ELO, STANDARD_LEVELS.SUB_ELO]


module.exports = {
  STANDARD_DROPDOWN_LIMIT_1000,
  TLO_LEVELS,
  ELO_LEVELS,
  STANDARD_LEVELS,
}
