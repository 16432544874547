const SMART_FILTERS = {
  ENTIRE_LIBRARY: 'ENTIRE_LIBRARY',
  AUTHORED_BY_ME: 'AUTHORED_BY_ME',
  SHARED_WITH_ME: 'SHARED_WITH_ME',
  CO_AUTHOR: 'CO_AUTHOR',
  PREVIOUSLY_USED: 'RECENT_PLAYLISTS',
  FAVORITES: 'FAVORITES',
  FOLDERS: 'FOLDERS',
  CO_AUTHOR_AND_USED_VQ: 'CO_AUTHOR_AND_USED_VQ',
}

const FILTER_KEYS = {
  STANDARD_IDS: 'standardIds',
}

module.exports = { SMART_FILTERS, FILTER_KEYS }
