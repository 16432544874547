const START = 0
const SUBMITTED = 1
const ABSENT = 2
const NOT_STARTED = 3
const UN_ASSIGNED = 4
const UN_ENROLLED = 5
// Constant to track the section submit
const SECTION_STATUS = {
  START: 0,
  SUBMITTED: 1,
  LOCKED: 2,
  NOT_STARTED: 3,
}
module.exports = {
  START,
  SUBMITTED,
  ABSENT,
  NOT_STARTED,
  UN_ASSIGNED,
  UN_ENROLLED,
  SECTION_STATUS,
}
