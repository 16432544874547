const POLICY_AUTO_ON_STARTDATE = 'Automatically on Start Date'
const POLICY_AUTO_ON_DUEDATE = 'Automatically on Due Date'
const POLICY_OPEN_MANUALLY_BY_ADMIN = 'Open Manually by Admin'
const POLICY_OPEN_MANUALLY_BY_TEACHER = 'Open Manually by Teacher'
const POLICY_OPEN_MANUALLY_IN_CLASS = 'Open Manually in Class'
const POLICY_CLOSE_MANUALLY_BY_ADMIN = 'Close Manually by Admin'
const POLICY_CLOSE_MANUALLY_IN_CLASS = 'Close Manually in Class'

const showPreviousAttemptOptions = {
  FEEDBACK_ONLY: 'FEEDBACK_ONLY',
  SCORE_AND_FEEDBACK: 'SCORE_AND_FEEDBACK',
  STUDENT_RESPONSE_AND_FEEDBACK: 'STUDENT_RESPONSE_AND_FEEDBACK',
  SCORE_RESPONSE_AND_FEEDBACK: 'SCORE_RESPONSE_AND_FEEDBACK',
}

module.exports = {
  POLICY_AUTO_ON_STARTDATE,
  POLICY_AUTO_ON_DUEDATE,
  POLICY_OPEN_MANUALLY_BY_ADMIN,
  POLICY_OPEN_MANUALLY_BY_TEACHER,
  POLICY_OPEN_MANUALLY_IN_CLASS,
  POLICY_CLOSE_MANUALLY_BY_ADMIN,
  POLICY_CLOSE_MANUALLY_IN_CLASS,
  showPreviousAttemptOptions,
}
