import { startCase, isEmpty, round } from 'lodash'

const _idToLabel = {
  schoolId: 'schoolName',
  teacherId: 'teacherName',
  groupId: 'groupName',
  // group: 'groupName', // compareByLabel is generated by groupId for student groups as well
  race: 'race',
  gender: 'gender',
  frlStatus: 'frlStatus',
  ellStatus: 'ellStatus',
  iepStatus: 'iepStatus',
  hispanicEthnicity: 'hispanicEthnicity',
}
export const idToLabel = (id) => _idToLabel[id] || id

const _idToName = {
  school: 'School',
  class: 'Class',
  group: 'Student Group',
  teacher: 'Teacher',
  race: 'Race',
  gender: 'Gender',
  frlStatus: 'FRL Status',
  ellStatus: 'ELL Status',
  iepStatus: 'IEP Status',
  hispanicEthnicity: 'Hispanic Ethnicity',
}
export const extAttributeIdToName = {
  extAttr_eip_status: 'Ext Attr EIP Status',
  eip_status: 'EIP Status',
  extAttr_status_504: 'Ext Attr 504 Status',
  status_504: '504 Status',
}
export const idToName = (id) =>
  _idToName[id] || extAttributeIdToName[id] || startCase(id)

export const analyseByToName = {
  'score(%)': 'Score (%)',
  rawScore: 'Raw Score',
  aboveBelowStandard: 'Above/Below Standard',
  proficiencyBand: 'Proficiency Band',
}

export const analyseByOptions = {
  scorePerc: 'score(%)',
  rawScore: 'rawScore',
  aboveBelowStandard: 'aboveBelowStandard',
  proficiencyBand: 'proficiencyBand',
}

export const compareByKeyMaps = [
  { key: 'school', title: 'School' },
  { key: 'teacher', title: 'Teacher' },
  { key: 'class', title: 'Class' },
  { key: 'group', title: 'Student Group' },
  { key: 'race', title: 'Race' },
  { key: 'gender', title: 'Gender' },
  { key: 'frlStatus', title: 'FRL Status' },
  { key: 'ellStatus', title: 'ELL Status' },
  { key: 'iepStatus', title: 'IEP Status' },
  { key: 'hispanicEthnicity', title: 'Hispanic Ethnicity' },
]

export const sortKeyMaps = {
  DIMENSION: 'dimension',
  DIM_SCORE_PERC: 'scorePerc',
  ABOVE_STANDARD: 'aboveStandard',
}
export const sortOrderMap = {
  ascend: 'asc',
  descend: 'desc',
}

const filterValue = (arr) => {
  const blackList = ['', 'null', 'undefined']
  return arr.filter((item) => !blackList.includes(item))
}
export const transformExtAttributes = (extAttributes) => {
  if (extAttributes?.length) {
    const transferredData = {}
    extAttributes.forEach((item) => {
      const value = filterValue(item.value)
      if (value?.length) transferredData[item.name] = value
    })
    return transferredData
  }
  return {}
}

export const transformExtAttributeFilters = (filterData) => {
  const _extDemogaphicFilters = filterData.map((item) => JSON.parse(item))
  const result = {}
  _extDemogaphicFilters.forEach((item) => {
    const [key, value] = Object.entries(item)[0]
    if (!result[key]) {
      result[key] = []
    }
    result[key].push(value)
  })
  return JSON.stringify(result)
}
export const getFormattedName = (item) => {
  return item
    ? item
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : ''
}
// This method can be imported from edulastic/constants reportUtils.peerPerformance
// constants import having issue in this file so kept the method here as well.
const getOverallAvg = (data, analyseBy) => {
  const { overallAvg, overallAvgPerf } = data[0]
  return analyseBy === analyseByOptions.scorePerc
    ? overallAvgPerf
      ? round(overallAvgPerf)
      : overallAvgPerf
    : overallAvg
}

// This method can be imported from edulastic/constants reportUtils.peerPerformance
// constants import having issue in this file so kept the method here as well.
const getOverallRow = (data, analyseBy, bandInfo) => {
  const districtAvg = getOverallAvg(data, analyseBy)
  const {
    submittedStudents,
    absentStudents,
    aboveStandard,
    belowStandard,
    totalStudents,
    performanceBandDetails,
    totalWeightedScore,
  } = data.reduce(
    (acc, curr) => {
      acc.submittedStudents += curr.submittedStudents
      acc.absentStudents += curr.absentStudents
      acc.aboveStandard += curr.aboveStandard
      acc.belowStandard += curr.belowStandard
      acc.totalStudents += curr.totalStudents
      acc.totalWeightedScore += curr.dimensionAvg * curr.submittedStudents
      if (!isEmpty(bandInfo)) {
        bandInfo.forEach(({ name }) => {
          acc.performanceBandDetails[name] =
            (acc.performanceBandDetails[name] || 0) + curr[name]
        })
      }
      return acc
    },
    {
      submittedStudents: 0,
      absentStudents: 0,
      aboveStandard: 0,
      belowStandard: 0,
      totalStudents: 0,
      performanceBandDetails: {},
      totalWeightedScore: 0,
    }
  )
  const dimensionAvg = totalWeightedScore / submittedStudents
  return {
    dimension: {
      _id: null,
      name: 'Overall',
    },
    districtAvg,
    dimensionAvg,
    submittedStudents,
    absentStudents,
    totalStudents,
    aboveStandard,
    belowStandard,
    ...performanceBandDetails,
  }
}

export const getTableData = (data, bandInfo, filter, analyseBy) => {
  // @TODO: Uncomment this code and return overallRowData to show overall row after 38.0.3 release fix.
  // const overallRowData = getOverallRow(data, analyseBy, bandInfo)
  const filteredData = data.filter((item) => {
    return filter[item.dimension._id] || Object.keys(filter).length === 0
  })
  return filteredData
}
