import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconWhiteMic = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="16.908"
    height="23.082"
    viewBox="0 0 16.908 23.082"
    {...props}
  >
    <path
      id="mic_FILL1_wght400_GRAD0_opsz48"
      d="M18.536,17.88a2.873,2.873,0,0,1-2.187-.942,3.214,3.214,0,0,1-.881-2.278V7.037a2.907,2.907,0,0,1,.9-2.156,3.117,3.117,0,0,1,4.343,0,2.907,2.907,0,0,1,.9,2.156V14.66a3.214,3.214,0,0,1-.881,2.278A2.873,2.873,0,0,1,18.536,17.88Zm0,9.2a.883.883,0,0,1-.911-.911V22.952a8.455,8.455,0,0,1-5.087-2.384,7.973,7.973,0,0,1-2.445-4.935.756.756,0,0,1,.228-.683.969.969,0,0,1,.714-.289.788.788,0,0,1,.577.243,1.069,1.069,0,0,1,.3.577A6.411,6.411,0,0,0,14.1,19.565a6.5,6.5,0,0,0,4.434,1.625,6.5,6.5,0,0,0,4.434-1.625,6.411,6.411,0,0,0,2.187-4.085,1.029,1.029,0,0,1,.319-.577.839.839,0,0,1,.592-.243.92.92,0,0,1,.7.289.774.774,0,0,1,.213.683,7.973,7.973,0,0,1-2.445,4.935,8.455,8.455,0,0,1-5.087,2.384v3.219a.883.883,0,0,1-.911.911Z"
      transform="translate(-10.083 -4)"
      fill="#fff"
    />
  </SVG>
)

export default withIconStyles(IconWhiteMic)
