import React from 'react'
import Progress from 'antd/lib/progress'
import { IconAIFileAttachment, IconCrossYtSearch } from '@edulastic/icons'
import { lightGreen11, white } from '@edulastic/colors'
import { views } from '../constants'
import {
  StyledUploaderContainer,
  StyledFileNameText,
  StyledUploadProgressBarContainer,
  StyledUploadPercentageNumber,
  StyledIconContainer,
  StyledFlexItemContainer,
} from '../styled-components'

function UploadInProgress({
  view,
  name,
  uploadPercent,
  uuid,
  handleCancelUpload,
}) {
  return (
    <StyledUploaderContainer
      width={view === views.TEST_SELECT_DOC_UPLOAD ? '70%' : '100%'}
    >
      <StyledFlexItemContainer
        flexGrow={view === views.ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS ? 1 : 0}
        marginLeft="10px"
      >
        <IconAIFileAttachment />
      </StyledFlexItemContainer>
      <StyledFileNameText
        {...(view === views.ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS && {
          'data-cy': 'aiFileName',
        })}
      >
        {name}
      </StyledFileNameText>
      <StyledUploadProgressBarContainer>
        <Progress
          percent={uploadPercent}
          size="small"
          strokeColor={lightGreen11}
          showInfo={false}
        />
      </StyledUploadProgressBarContainer>
      <StyledUploadPercentageNumber>
        {uploadPercent}%
      </StyledUploadPercentageNumber>
      <StyledIconContainer
        data-cy="cancelUploadIcon"
        cursor="pointer"
        onClick={() => handleCancelUpload(uuid)}
      >
        <IconCrossYtSearch fillColor={white} />
      </StyledIconContainer>
    </StyledUploaderContainer>
  )
}

export default UploadInProgress
