module.exports = {
  PRODUCT_NAMES: {
    TEACHER_PREMIUM: 'Teacher Premium',
    SPARK_MATH: 'Spark Math',
  },
  SUBSCRIPTION_SUB_TYPES: {
    PARTIAL_PREMIUM: 'partial_premium',
    ENTERPRISE: 'enterprise',
  },
}
