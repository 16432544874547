const questionTitle = {
  MCQ_STANDARD: 'Multiple Choice - Standard',
  MCQ_TRUE_OR_FALSE: 'True or False',
  EXPRESSION_AND_FORMULA: 'Expression & Formula',
  EQUATION_AND_INEQUALITIES: 'Equations & Inequalities',
  NUMERIC_ENTRY: 'Numeric Entry',
  PASSAGE_WITH_QUESTIONS: 'Passage with Questions',
  CLOZE_DRAG_DROP: 'Drag & Drop',
  EDITING_TASK: 'Editing Task',
  SORT_LIST: 'Sort List',
  EXPRESSION_MULTIPART: 'Math, Text & Dropdown',
  CLOZE_IMAGE_TEXT: 'Label Image with Text',
  CLOZE_IMAGE_DRAG_DROP: 'Label Image with Drag & Drop',
  CLOZE_IMAGE_DROP_DOWN: 'Label Image with Drop Down',
  CLOZE_DROP_DOWN: 'Text Drop Down',
  CLOZE_TEXT: 'Text Entry',
  CLASSIFICATION: 'Classification',
  CHOICE_MATRIX_LABELS: 'Match Table - Labels',
  CHOICE_MATRIX_INLINE: 'Match Table - Inline',
  SHORT_TEXT: 'Short Text',
  ESSAY_PLAIN_TEXT: 'Essay with Plain Text',
  ESSAY_RICH_TEXT: 'Essay with Rich Text',
  MATCH_LIST: 'Match the following',
  MCQ_BLOCK_LAYOUT: 'Multiple Choice - Block Layout',
  MCQ_MULTIPLE_RESPONSE: 'Multiple Selection',
  CHOICE_MATRIX_STANDARD: 'Match Table - Standard',
  RE_SEQUENCE: 'Re-sequence',
  MATRICES: 'Matrices',
  FORMULA_ESSAY: 'Math Essay',
  AUDIO_RESPONSE: 'Audio Response',
  LIKERT_SCALE: 'Likert Scale',
}
module.exports = questionTitle
