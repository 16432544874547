import styled from 'styled-components'
import { IconCheck, IconWrong, IconUploader } from '@edulastic/icons'
import { textColor } from '@edulastic/colors'

export const StyledFileInput = styled.input`
  display: none;
`
export const StyledUploaderContainer = styled.div`
  width: ${(props) => props?.width || '100%'};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`
export const StyledFlexItemContainer = styled.div`
  flex-grow: ${(props) => props?.flexGrow || 'unset'};
  margin-right: ${(props) => props?.marginRight || 'unset'};
  margin-left: ${(props) => props?.marginLeft || 'unset'};
`
export const StyledAIDocumentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: ${(props) => props?.flexGrow || 1};
`
export const StyledUploadAttachmentText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #000000;
`
export const StyledSupportedFilesText = styled.span`
  font-family: Open Sans;
  font-size: ${(props) => props?.fontSize || '12px'};
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #777777;
  margin-top: ${(props) => props?.marginTop || 'unset'};
`
export const StyledButtonText = styled.span`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`
export const StyledFileNameText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: ${(props) => props?.width || '119px'};
  height: 20px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const StyledUploadProgressBarContainer = styled.div`
  width: 248px;
  .ant-progress-inner {
    background-color: #d8d8d8;
  }
`
export const StyledUploadPercentageNumber = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #2f4151;
`
export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2f4151;
  margin-right: 8px;
  cursor: ${(props) => props?.cursor || 'unset'};
`
export const StyledUploadStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 104px;
`
export const StyledIconCheck = styled(IconCheck)`
  width: 12px;
  height: 10px;
  fill: #578f1e;
  &:hover {
    fill: #578f1e;
  }
`
export const StyledIconWrong = styled(IconWrong)`
  width: 9px;
  height: 9px;
  fill: #e43334;
  &:hover {
    fill: #e43334;
  }
`
export const StyledUploadStatusText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  color: ${(props) => (props?.isSuccess ? '#578f1e' : '#E43334')};
`
export const StyledUploadActionContainer = styled.div`
  border-left: 1px solid #bbbbbb;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  cursor: pointer;
  padding-left: ${(props) => props?.paddingLeft || 'unset'};
  white-space: nowrap;
`
export const StyledActionText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  margin-left: 6px;
  color: ${(props) => props?.textColor || '#11a084'};
`
export const StyledUploadIcon = styled(IconUploader)`
  height: 48px;
  width: 48px;
  margin-bottom: 15px;
  fill: ${textColor};
`
export const StyledDocUploadTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
`
export const StyledDividerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`
export const StyledORText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #555555;
  padding-bottom: 5px;
`
