export const AUTH_FLOW = {
  IMPLICIT: 'implicit',
  CODE: 'code',
}

export const AUTH_METHOD = {
  TOKEN: 'initTokenClient',
  CODE: 'initCodeClient',
}

export const G_DRIVE_ACTIONS = {
  PICKED: 'picked',
}

export const DEFAULT_SCOPES = `openid profile email`
