const EMAIL_EXIST = 'email already exists'
const ACCOUNT_CREATED = 'account successfully created'
const INVALID_TEST_ITEM = 'invalid test item'
const ASSIGNMENT_ALREADY_SUBMITTED = 'assignment already submitted'
const INVALID_ASSIGNMENT = 'invalid assignment id'
const TEST_ALREADY_SUBMITTED = 'test already submitted'
const NOT_OWNER = 'not your resource'
const ASSIGNMENT_NOT_STARTED = 'assignment is not started yet'

module.exports = {
  EMAIL_EXIST,
  ACCOUNT_CREATED,
  ASSIGNMENT_ALREADY_SUBMITTED,
  INVALID_TEST_ITEM,
  INVALID_ASSIGNMENT,
  TEST_ALREADY_SUBMITTED,
  NOT_OWNER,
  ASSIGNMENT_NOT_STARTED,
}
