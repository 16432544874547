const INNER_DIFFERENCE = 'innerDifference'
const INCLUDES = 'includes'
const IS_EQUAL = 'isEqual'
const MCQ_TYPE = 'mcqType'

module.exports = {
  INNER_DIFFERENCE,
  INCLUDES,
  IS_EQUAL,
  MCQ_TYPE,
}
