const MATCH_TYPE = {
  CONTAINS: 'cont',
  EQUALS: 'eq',
}

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

module.exports = { MATCH_TYPE, SORT_ORDER }
