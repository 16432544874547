import React from 'react'
import { Menu, Dropdown } from 'antd'
import styled from 'styled-components'

import { lightGrey16 } from '@edulastic/colors'

import EduButton from './EduButton'

const { Item, Divider } = Menu

const ActionsMenu = ({ options = [], showDivider }) => {
  return (
    <StyledMenu>
      {options.map((option, idx) => [
        <Item>{option}</Item>,
        showDivider && idx !== options.length - 1 ? <Divider /> : <div />,
      ])}
    </StyledMenu>
  )
}

const ActionsDropdown = ({
  options,
  triggerElement = <EduButton>Actions</EduButton>,
  triggerEvent = 'hover',
  showDivider = false,
}) => {
  return (
    <Dropdown
      overlay={<ActionsMenu options={options} showDivider={showDivider} />}
      trigger={[triggerEvent]}
    >
      {triggerElement}
    </Dropdown>
  )
}

/** @type {typeof Menu} */
const StyledMenu = styled(Menu)`
  font-weight: 600;
  font-size: 12px;
  border: 1px solid ${lightGrey16};
  border-radius: 4px;
  .ant-menu-item {
    height: 35px;
    line-height: 35px;
  }
`

export default ActionsDropdown
