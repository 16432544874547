/* eslint-disable func-names */
import { notification } from '@edulastic/common'
import { aws } from '@edulastic/constants'
import {
  uploadToS3,
  convertBlobToFile,
  canInsert,
  beforeUpload,
  captureSentryException,
} from '../../helpers'
import { loadImage } from './helpers'
import { showProgressBar, hideProgressBar } from './CustomLoader'

const uploadImage = (froalaEditor, image) => {
  froalaEditor.popups.hideAll()
  showProgressBar()
  froalaEditor.edit.off()

  if (
    !canInsert(froalaEditor.selection.element()) ||
    !canInsert(froalaEditor.selection.endElement()) ||
    !beforeUpload(image)
  ) {
    hideProgressBar()
    return false
  }

  uploadToS3(image, aws.s3Folders.DEFAULT)
    .then((result) => {
      hideProgressBar()

      // replacing if image is from pasted content
      const imageId = image.paId
      if (
        imageId &&
        froalaEditor.$doc.find(`img[data-pa-id="${imageId}"]`).length
      ) {
        froalaEditor.image.insert(
          result,
          true,
          { 'pa-id': null },
          $(`img[data-pa-id="${imageId}"]`)
        )
      } else {
        froalaEditor.image.insert(result, false, null)
      }
    })
    .catch((err) => {
      console.error(err)
      captureSentryException(err, {
        errorMessage: 'FroalaEditor - failed to upload image',
      })
      hideProgressBar()
      notification({ messageKey: 'imageUploadErr' })
      froalaEditor.edit.on()
    })
}

function imageUploadPlugin(FroalaEditor) {
  FroalaEditor.PLUGINS.customImageUploadPlugin = function (editor) {
    function imageBeforeUpload(images) {
      uploadImage(this, images[0])

      return false
    }

    async function imageInserted($img) {
      try {
        const { imageDefaultWidth } = editor.opts
        if (!$img[0].complete) {
          await loadImage($img[0].src)
          $img.css({
            verticalAlign: 'middle',
            width:
              $img[0].naturalWidth < imageDefaultWidth
                ? `${$img[0].naturalWidth}px`
                : `${imageDefaultWidth}px`,
          })
        }
      } catch (e) {
        notification({ messageKey: 'imageLoadErr' })
      } finally {
        this.popups.hideAll()
        this.edit.on()
      }
      return false
    }

    function beforePasteUpload(img) {
      fetch(img.src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = convertBlobToFile(blob)
          file.paId = img.getAttribute('data-pa-id')
          // uploading image and replacing it with uploaded image
          uploadImage(this, file)
        })
      img?.remove()
      return false
    }

    function _init() {
      editor.events.on('image.beforeUpload', imageBeforeUpload)
      editor.events.on('image.beforePasteUpload', beforePasteUpload)
      editor.events.on('image.inserted', imageInserted)
      // adding custom ID to pasted images in order to replace them after upload
      editor.events.on('paste.afterCleanup', (clipboard_html) => {
        try {
          const htmlData = $(`<span>${clipboard_html}</span>`)
          htmlData.find('img').each((index, img) => {
            $(img).attr('data-pa-id', `img-${index}`)
          })
          return htmlData.first().html()
        } catch {
          return clipboard_html
        }
      })
    }

    return {
      _init,
    }
  }
}

export default imageUploadPlugin
