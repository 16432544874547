import React from 'react'
import { EduButton } from '@edulastic/common'
import { StyledButtonText } from '../styled-components'
import { GooglePicker } from '../../../../../../src/vendors/google'

function DefaultUploader({
  fileInputClickHandler,
  onSelect,
  onAuthFailed,
  allowedFileTypes,
  showUploadFromDriveButton,
}) {
  return (
    <div>
      <EduButton
        data-cy="uploadFromDeviceButton"
        height="32px"
        onClick={fileInputClickHandler}
      >
        <StyledButtonText>UPLOAD</StyledButtonText>
      </EduButton>
      {showUploadFromDriveButton && (
        <EduButton data-cy="uploadFromDriveButton" height="32px">
          <GooglePicker
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            onChange={onSelect}
            onAuthFailed={onAuthFailed}
            mimeTypes={allowedFileTypes}
          >
            <StyledButtonText>UPLOAD FROM DRIVE</StyledButtonText>
          </GooglePicker>
        </EduButton>
      )}
    </div>
  )
}

export default DefaultUploader
