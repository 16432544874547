import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCharInfo = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.34 7.56" {...props}>
    <path
      d="M34.379,6.745l-.106.432q-.475.188-.759.286a2,2,0,0,1-.658.1,1.313,1.313,0,0,1-.895-.281.908.908,0,0,1-.319-.713,2.522,2.522,0,0,1,.024-.343q.024-.176.077-.4l.4-1.4q.053-.2.089-.382a1.659,1.659,0,0,0,.036-.329.508.508,0,0,0-.111-.375.626.626,0,0,0-.424-.106,1.11,1.11,0,0,0-.315.048c-.108.032-.2.063-.277.091l.106-.432q.389-.158.745-.271a2.227,2.227,0,0,1,.673-.113,1.281,1.281,0,0,1,.881.276.917.917,0,0,1,.31.718c0,.061-.007.168-.021.322a2.152,2.152,0,0,1-.079.423l-.394,1.4a3.709,3.709,0,0,0-.087.385,1.979,1.979,0,0,0-.039.326.47.47,0,0,0,.124.379.7.7,0,0,0,.431.1,1.246,1.246,0,0,0,.326-.05A1.824,1.824,0,0,0,34.379,6.745Zm.1-5.862A.817.817,0,0,1,34.2,1.5a.939.939,0,0,1-.664.257.95.95,0,0,1-.668-.257.815.815,0,0,1-.278-.622.823.823,0,0,1,.278-.624.986.986,0,0,1,1.332,0A.825.825,0,0,1,34.479.883Z"
      transform="translate(-31.139 0)"
      fill="#b5b5b5"
    />
  </SVG>
)

export default withIconStyles(IconCharInfo)
