import { omit } from 'lodash'
import API from './utils/API'

const api = new API()
const prefix = '/canvas'

const getCanvasAuthURI = (institutionId, type = '') =>
  api
    .callApi({
      url: `${prefix}/sso-details?institutionId=${institutionId}${
        type ? `&type=${type}` : ''
      }`,
      method: 'get',
    })
    .then((result) => result.data.result)

const fetchCourseList = (institutionId) =>
  api
    .callApi({
      url: `${prefix}/course-list?institutionId=${institutionId}`,
      method: 'get',
    })
    .then((result) => result.data.result)

const fetchCourseSectionList = ({ allCourseIds, institutionId }) =>
  api
    .callApi({
      url: `${prefix}/course-section-list?courseIds=${allCourseIds.toString()}&institutionId=${institutionId}`,
      method: 'get',
    })
    .then((result) => result.data.result)

const bulkSync = (data) =>
  api
    .callApi({
      url: `${prefix}/sync-all-classes`,
      method: 'post',
      data,
    })
    .then((result) => result.data.result)

const canvasSync = (data) =>
  api
    .callApi({
      url: `${prefix}/start-canvas-sync`,
      method: 'post',
      data,
    })
    .then((result) => result.data.result)

const canvasGradesSync = (data) =>
  api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/sync-grades-manually`,
      method: 'post',
      data,
    })
    .then((result) => result.data.result)

const canvasAssignmentSync = (data) =>
  api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/sync-assignment-manually`,
      method: 'post',
      data,
    })
    .then((result) => result.data.result)

const getCanvasAccounts = () =>
  api
    .callApi({
      url: `${prefix}/canvasAccounts`,
      method: 'get',
    })
    .then((result) => result)

const saveSchoolMappingForCanvasSync = (data) =>
  api
    .callApi({
      url: `${prefix}/canvasSchoolsMapping`,
      method: 'post',
      data,
    })
    .then((result) => result)
const getSchoolMappingForCanvasAccount = (data) =>
  api
    .callApi({
      url: `${prefix}/canvasSchoolsMapping/${data.canvasAccountId}`,
      method: 'get',
      data,
    })
    .then((result) => result)

const canvasDistrictSync = (data) =>
  api
    .callApi({
      url: `${prefix}/sync-district/${data.districtId}`,
      method: 'post',
      data: omit(data, ['districtId']),
    })
    .then((result) => result)
export default {
  getCanvasAuthURI,
  fetchCourseList,
  fetchCourseSectionList,
  bulkSync,
  canvasSync,
  canvasGradesSync,
  canvasAssignmentSync,
  getCanvasAccounts,
  saveSchoolMappingForCanvasSync,
  getSchoolMappingForCanvasAccount,
  canvasDistrictSync,
}
