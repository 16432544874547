module.exports = {
  nonPremiumCollections: {
    '6564756c61737469635f656e': 'Engage Ny',
    '536d61727453746172745f44': 'SmartStart Diagnostics',
    '6564756c61737469635f6365': 'Pear Assessment Certified',
    '5e3aa8ba83bf19bcab67d0bb': 'CLILESSONS Bank',
    '5e3aa8ba83bf19bcab67d0cb': 'CLI SC MSMS Bank',
    '5e3aa8ba83bf19bcab67d0cc': 'CLI SC HSMS Bank',
    '5e3aa8ba83bf19bcab67d0ce': 'CLI HSMS A1 Assignment Bank',
    '5e3aa8ba83bf19bcab67d0d2': 'CLI FL',
    '5e3aa8ba83bf19bcab67d0d3': 'CLI CC',
    '5e3aa8ba83bf19bcab67d0d5': 'CLI TX',
    '5e3aa8ba83bf19bcab67d0f5': 'CLI World Languages Spanish',
    '5ee7402731902a0008c8fef4': 'CLI_DATA_V',
    '5e0f3c5b03b7ad0924ef0aa9': 'CLI MATHia Bank',
    '5e0f3c5b03b7ad0924ef0aad': 'CLILESSONS Bank',
    '5e0f3c5b03b7ad0924ef0ab5': 'CLI MSMS Bank',
    '5e0f3c5b03b7ad0924ef0aba': 'CLI HSMS Integrated Bank',
    '5e0f3c5b03b7ad0924ef0abb': 'CLI HSMS Traditional Bank',
    '5e0f3c5b03b7ad0924ef0ac1': 'CLI SC MSMS Bank',
    '5e0f3c5b03b7ad0924ef0ac2': 'CLI SC HSMS Bank',
    '5e0f3c5b03b7ad0924ef0ac3': 'CLI HSMS A1 Assignment Bank',
    '5e0f3c5b03b7ad0924ef0ac7': 'CLI FL',
    '5e0f3c5b03b7ad0924ef0ac8': 'CLI CC',
    '5e0f3c5b03b7ad0924ef0ac9': 'CLI TX',
    '5e0f3c5b03b7ad0924ef0ae1': 'CLI World Languages Spanish',
    '5f0da8996ea7a80007cb65d8': 'CLI World Languages Chinese',
    '5f0eb316c5a0450007eaa2d9': 'CLI World Languages French',
    '5ef23b2a588604000731e38f': 'CL Mirrors and Windows',
  },

  nonPremiumCollectionsToShareContent: {
    '6564756c61737469635f656e': 'Engage Ny',
    '6564756c61737469635f6365': 'Pear Assessment Certified',
    '536d61727453746172745f44': 'SmartStart Diagnostics',
  },

  types: {
    FREE: 'free',
    CUSTOM: 'custom',
    PREMIUM: 'premium',
  },
}
