const defaultSymbols = [
  'basic',
  'intermediate',
  'advanced',
  'units_si',
  'units_us',
  'all',
]

module.exports = defaultSymbols
