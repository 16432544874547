import styled from 'styled-components'

const PremiumLabel = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
  height: 20px;
  background: #feb63a;
  color: white;
  font-weight: bold;
  padding: 3px 15px;
  border-radius: 5px;
`

export default PremiumLabel
