import React from 'react'
import PropTypes from 'prop-types'
import { aws, fileTypes } from '@edulastic/constants'
import { fileUploadStatusTypes, views } from './constants'
import { useFileUploader } from './hooks/useFileUploader'
import { useFileUploadFromDrive } from './hooks/useFileUploadFromDrive'
import { StyledFileInput } from './styled-components'
import AIItemsUploader from './components/AIItemsUploader'
import DocumentUploader from './components/DocumentUploader'
import DefaultUploader from './components/DefaultUploader'
import QtiWebctUploader from './components/QtiWebctUploader'
import UploadInProgress from './components/UploadInProgress'
import UploadStatus from './components/UploadStatus'

const FileUploader = ({
  fileDropAreaRef,
  maxFilesCount,
  allowedFileTypes,
  view,
  s3Folder,
  maxFileSize,
  uploadType,
  showUploadFromDriveButton,
  resetAllData,
  unsupportedFileCallback,
  maxFileSizeExceedCallback,
  fileUploadProgressCallback,
  fileUploadFinishCallback,
  fileUploadFailedCallback,
  uploadCancelCallback,
  fileDeleteCallback,
  maxFileCountExceededCallback,
  onAuthFailedCallback,
  onDriveFileSelectCallback,
}) => {
  const {
    onSelect,
    onAuthFailed,
    onDelete: onDeleteUploadedFileFromDrive,
    cancelUpload: onCancelUploadFromDrive,
    isUploadInProgress: isFileFromDriveUploadInProgress,
    filesData: uploadFromDriveFilesData,
  } = useFileUploadFromDrive({
    maxFileSize,
    resetAllData,
    s3Folder,
    uploadType,
    onAuthFailedCallback,
    onDriveFileSelectCallback,
    fileUploadProgressCallback,
    maxFileSizeExceedCallback,
    fileUploadFinishCallback,
    fileUploadFailedCallback,
    fileDeleteCallback,
  })

  const {
    isUploadInProgress: _isUploadInProgress,
    filesData: _filesData,
    fileInputRef,
    handleFileInputChange,
    cancelUpload,
    fileInputClickHandler,
    resetFileInputValue,
    onDelete,
  } = useFileUploader({
    fileDropAreaRef,
    s3Folder,
    maxFileSize,
    allowedFileTypes,
    maxFilesCount: maxFilesCount - (uploadFromDriveFilesData || []).length,
    uploadType,
    resetAllData,
    maxFileSizeExceedCallback,
    unsupportedFileCallback,
    fileUploadProgressCallback,
    fileUploadFinishCallback,
    fileUploadFailedCallback,
    uploadCancelCallback,
    fileDeleteCallback,
    maxFileCountExceededCallback,
  })

  const isUploadInProgress =
    _isUploadInProgress || isFileFromDriveUploadInProgress
  const filesData = [..._filesData, ...uploadFromDriveFilesData]

  const handleDelete = (uuid) => {
    onDelete(uuid)
    onDeleteUploadedFileFromDrive(uuid)
  }

  const handleCancelUpload = (uuid) => {
    onCancelUploadFromDrive(uuid)
    cancelUpload(uuid)
  }

  const getUploadComponent = () => {
    if (view === views.ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS)
      return <AIItemsUploader fileInputClickHandler={fileInputClickHandler} />
    if (view === views.TEST_SELECT_DOC_UPLOAD)
      return (
        <DocumentUploader
          {...{
            fileInputClickHandler,
            onSelect,
            onAuthFailed,
            allowedFileTypes,
          }}
        />
      )
    if (
      view === views.TEST_SELECT_QTI_UPLOAD ||
      view === views.TEST_SELECT_WEBCT_UPLOAD
    )
      return (
        <QtiWebctUploader
          fileInputClickHandler={fileInputClickHandler}
          view={view}
        />
      )
    return (
      <DefaultUploader
        {...{
          fileInputClickHandler,
          onSelect,
          onAuthFailed,
          allowedFileTypes,
          showUploadFromDriveButton,
        }}
      />
    )
  }

  return (
    <>
      {isUploadInProgress &&
        filesData.map((fileData) => {
          const { name, uploadPercent, status, uuid } = fileData
          const isUploadSuccess = status === fileUploadStatusTypes.SUCCESS
          const isUploadFailed = status === fileUploadStatusTypes.FAILED
          if (status === fileUploadStatusTypes.IN_PROGRESS) {
            return (
              <UploadInProgress
                {...{
                  key: uuid,
                  name,
                  uuid,
                  view,
                  uploadPercent,
                  handleCancelUpload,
                }}
              />
            )
          }

          if (isUploadSuccess || isUploadFailed) {
            return (
              <UploadStatus
                {...{
                  key: uuid,
                  name,
                  uuid,
                  view,
                  isUploadSuccess,
                  handleDelete,
                }}
              />
            )
          }
          return null
        })}

      {!isUploadInProgress && (
        <>
          <StyledFileInput
            multiple={maxFilesCount > 1}
            type="file"
            data-testid="fileUpload"
            ref={fileInputRef}
            onClick={resetFileInputValue}
            onChange={handleFileInputChange}
            accept={allowedFileTypes.join()}
          />
          {getUploadComponent()}
        </>
      )}
    </>
  )
}

FileUploader.propTypes = {
  fileDropAreaRef: PropTypes.object,
  maxFilesCount: PropTypes.number.isRequired,
  allowedFileTypes: PropTypes.array,
  view: PropTypes.string,
  s3Folder: PropTypes.string,
  maxFileSize: PropTypes.number.isRequired,
  uploadType: PropTypes.string,
  resetAllData: PropTypes.bool,
  showUploadFromDriveButton: PropTypes.bool,
  unsupportedFileCallback: PropTypes.func,
  fileUploadProgressCallback: PropTypes.func,
  fileUploadFinishCallback: PropTypes.func,
  fileUploadFailedCallback: PropTypes.func,
  uploadCancelCallback: PropTypes.func,
  fileDeleteCallback: PropTypes.func,
  maxFileCountExceededCallback: PropTypes.func,
  onAuthFailedCallback: PropTypes.func,
  onDriveFileSelectCallback: PropTypes.func,
}

FileUploader.defaultProps = {
  fileDropAreaRef: null,
  allowedFileTypes: [fileTypes.ALL],
  view: '',
  s3Folder: aws.s3Folders.DEFAULT,
  uploadType: '',
  showUploadFromDriveButton: false,
  resetAllData: false,
  unsupportedFileCallback: () => {},
  fileUploadProgressCallback: () => {},
  fileUploadFinishCallback: () => {},
  fileUploadFailedCallback: () => {},
  uploadCancelCallback: () => {},
  fileDeleteCallback: () => {},
  maxFileCountExceededCallback: () => {},
  onAuthFailedCallback: () => {},
  onDriveFileSelectCallback: () => {},
}

export default FileUploader
