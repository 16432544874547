const itemList = [
  { value: 'popularity', text: 'Popularity' },
  { value: 'recency', text: 'Recency' },
  { value: 'author', text: 'Author' },
  { value: 'likes', text: 'Likes' },
]

const testList = [
  { value: 'relevance', text: 'Relevance' },
  { value: 'popularity', text: 'Popularity' },
  { value: 'recency', text: 'Recency' },
  { value: 'title', text: 'Name' },
  { value: 'likes', text: 'Likes' },
]

const playList = [
  { value: 'relevance', text: 'Relevance' },
  { value: 'popularity', text: 'Popularity' },
  { value: 'recency', text: 'Recency' },
  { value: 'title', text: 'Name' },
]

module.exports = {
  itemList,
  testList,
  playList,
}
