import { questionType } from '@edulastic/constants'

export const ALWAYS = 'always'
export const ON_LIMIT = 'on-limit'
export const COPY = 'copy'
export const CUT = 'cut'
export const PASTE = 'paste'
export const OFF = 'off'
export const PREVIEW = 'preview'
export const EDIT = 'edit'
export const CLEAR = 'clear'
export const SHOW = 'show'
export const CHECK = 'check'
export const CONTAINS = 'contains'
export const EXACT_MATCH = 'exactMatch'
export const PARAGRAPH_MODE = 'paragraph'
export const SENTENCE_MODE = 'sentence'
export const WORD_MODE = 'word'
export const DRAW_MODE = 'draw_mode'
export const DELETE_MODE = 'delete_mode'
export const CUSTOM_MODE = 'custom'
export const TRANSCRIBE = 'transcribe'

export const BY_LOCATION_METHOD = 'byLocation'
export const BY_COUNT_METHOD = 'byCount'

export const WIDTH = 'width'
export const HEIGHT = 'height'
export const IMAGE_ORIGINAL_HEIGHT = 'imageOriginalHeight'
export const IMAGE_ORIGINAL_WIDTH = 'imageOriginalWidth'
export const ALT_TEXT = 'altText'
export const SOURCE = 'source'

export const templateWithTokens = [
  {
    value: 'Risus et tincidunt turpis facilisis.',
    active: true,
  },
  {
    value: '<br/>Curabitur eu nulla justo.',
    active: false,
  },
  {
    value: ' Curabitur vulputate ut nisl et bibendum.',
    active: false,
  },
  {
    value: ' Nunc diam enim, porta sed eros vitae.',
    active: true,
  },
  {
    value: ' dignissim, et tincidunt turpis facilisis.',
    active: false,
  },
  {
    value: '<br/>Curabitur eu nulla justo.',
    active: false,
  },
  {
    value: ' Curabitur vulputate ut nisl et bibendum.',
    active: true,
  },
]

export const previewAreas = [
  [
    {
      x: 90.40625,
      y: 7,
    },
    {
      x: 64.40625,
      y: 13,
    },
    {
      x: 39.40625,
      y: 13,
    },
    {
      x: 13.40625,
      y: 17,
    },
    {
      x: 7.40625,
      y: 30,
    },
    {
      x: 11.40625,
      y: 45,
    },
    {
      x: 26.40625,
      y: 35,
    },
    {
      x: 24.40625,
      y: 57,
    },
    {
      x: 36.40625,
      y: 74,
    },
    {
      x: 50.40625,
      y: 86,
    },
    {
      x: 57.40625,
      y: 101,
    },
    {
      x: 63.40625,
      y: 114,
    },
    {
      x: 68.40625,
      y: 127,
    },
    {
      x: 72.40625,
      y: 142,
    },
    {
      x: 77.40625,
      y: 155,
    },
    {
      x: 90.40625,
      y: 163,
    },
    {
      x: 92.40625,
      y: 150,
    },
    {
      x: 101.40625,
      y: 135,
    },
    {
      x: 113.40625,
      y: 120,
    },
    {
      x: 119.40625,
      y: 102,
    },
    {
      x: 106.40625,
      y: 93,
    },
    {
      x: 84.40625,
      y: 75,
    },
    {
      x: 77.40625,
      y: 61,
    },
    {
      x: 90.40625,
      y: 52,
    },
    {
      x: 117.40625,
      y: 35,
    },
    {
      x: 132.40625,
      y: 25,
    },
    {
      x: 145.40625,
      y: 17,
    },
    {
      x: 142.40625,
      y: 4,
    },
    {
      x: 129.40625,
      y: 2,
    },
    {
      x: 110.40625,
      y: 2,
    },
  ],
  [
    {
      x: 260.40625,
      y: 116,
    },
    {
      x: 256.40625,
      y: 130,
    },
    {
      x: 266.40625,
      y: 143,
    },
    {
      x: 279.40625,
      y: 142,
    },
    {
      x: 299.40625,
      y: 143,
    },
    {
      x: 305.40625,
      y: 123,
    },
    {
      x: 294.40625,
      y: 108,
    },
    {
      x: 274.40625,
      y: 107,
    },
  ],
]

export const defaultOptions = ['Option 1', 'Option 2', 'Option 3', 'Option 4']

// fraction formats
export const FRACTION_FORMATS = {
  decimal: 'Decimal',
  fraction: 'Fraction',
  mixedFraction: 'MixedFraction',
}

export const sttEnabledQuestionTypes = [
  questionType.ESSAY_PLAIN_TEXT,
  questionType.ESSAY_RICH_TEXT,
]

export const stackedTranslationEnabledQuestionTypes = [
  questionType.ESSAY_PLAIN_TEXT,
  questionType.ESSAY_RICH_TEXT,
  questionType.MULTIPLE_CHOICE,
]
