const RECORDING_INACTIVE = 'RECORDING_INACTIVE'
const RECORDING_ACTIVE = 'RECORDING_ACTIVE'
const RECORDING_COMPLETED = 'RECORDING_COMPLETED'
const PLAY = 'PLAY'
const PAUSE = 'PAUSE'
const STOP = 'STOP'
const MIC = 'MIC'
const START = 'START'
const AUDIO_UPLOAD_INACTIVE = 'AUDIO_UPLOAD_INACTIVE'
const AUDIO_UPLOAD_ACTIVE = 'AUDIO_UPLOAD_ACTIVE'
const AUDIO_UPLOAD_SUCCESS = 'AUDIO_UPLOAD_SUCCESS'
const AUDIO_UPLOAD_ERROR = 'AUDIO_UPLOAD_ERROR'
const maxAudioDurationLimit = 5

export {
  RECORDING_INACTIVE,
  RECORDING_ACTIVE,
  RECORDING_COMPLETED,
  PLAY,
  PAUSE,
  STOP,
  MIC,
  START,
  AUDIO_UPLOAD_INACTIVE,
  AUDIO_UPLOAD_ACTIVE,
  AUDIO_UPLOAD_SUCCESS,
  AUDIO_UPLOAD_ERROR,
  maxAudioDurationLimit,
}
