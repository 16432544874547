/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconOnWhiteLogo = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 97" {...props}>
    <title>Edulastic_logo_mark</title>
    <defs />
    <g
      id="Brandind-guides"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Edulastic_logo_mark" fillRule="nonzero">
        <g id="Group" fill="#00A37C">
          <polygon
            id="Shape"
            points="18.8 16.1 18.8 36.9 48 36.9 48 52.9 18.8 52.9 18.8 78.4 49.2 78.4 49.2 94.4 0.4 94.4 0.4 0.2 49.2 0.2 49.2 16.2 18.8 16.2"
          />
          <path
            d="M104.5,88.6 L104.3,88.6 C100.7,93.6 94.2,96.4 87.8,96.4 C69.8,96.4 59.6,80 59.6,63.4 C59.6,47.2 70,31 87.6,31 C94,31 100.5,33.8 104.4,38.6 L104.6,38.6 L104.6,0.4 L122,0.4 L122,94.4 L104.5,94.4 L104.5,88.6 Z M106.3,63.6 C106.3,55.4 100.8,46.5 91.8,46.5 C82.8,46.5 77.3,55.4 77.3,63.6 C77.3,71.8 82.8,80.8 91.8,80.8 C100.8,80.8 106.3,71.8 106.3,63.6 Z"
            id="Shape"
          />
          <path
            d="M154.7,33 L154.7,66.2 C154.7,73.2 155.8,81.6 164.7,81.6 C173.6,81.6 174.7,73.2 174.7,66.2 L174.7,33 L192.2,33 L192.2,69.1 C192.2,87 183,96.3 164.7,96.3 C146.4,96.3 137.2,86.9 137.2,69.1 L137.2,33 L154.7,33 Z"
            id="Shape"
          />
        </g>
        <g
          id="Group"
          transform="translate(207.000000, 0.000000)"
          fill="#253243"
        >
          <polygon id="Shape" points="6.2 95 0.6 95 0.6 0 6.2 0" />
          <path
            d="M76.2,95 L70.6,95 L70.6,84.6 L70.4,84.6 C66.2,92 56.9,96.2 48.4,96.2 C30.9,96.2 19.9,82.6 19.9,65.7 C19.9,48.8 30.8,35.9 48.1,35.9 C57,35.9 65.5,39.9 70.3,47.1 L70.5,47.1 L70.5,37.1 L76.1,37.1 L76.1,95 L76.2,95 Z M25.5,65.8 C25.5,79 34.3,91 48.3,91 C63.1,91 71.1,79 71.1,65.1 C71.1,51.7 62,41.2 48,41.2 C34.6,41.2 25.5,53.2 25.5,65.8 Z"
            id="Shape"
          />
          <path
            d="M119.7,47.8 C117.8,43.8 114.1,41.3 109.6,41.3 C104.7,41.3 99.7,45.3 99.7,50.3 C99.7,64.2 127.8,57.4 127.8,78.3 C127.8,88.9 119.6,96.3 109.2,96.3 C99.6,96.3 91.7,90.4 89.3,81.1 L94.8,79.3 C96.9,86.2 101.6,91.1 109.2,91.1 C116.4,91.1 122.2,85.3 122.2,78.1 C122.2,71 116.7,68.7 111,66.7 C103.1,63.8 94.1,60.5 94.1,50.7 C94.1,41.9 101.5,36.1 109.9,36.1 C115.8,36.1 122.1,40 124.7,45.3 L119.7,47.8 Z"
            id="Shape"
          />
          <polygon
            id="Shape"
            points="148.6 95 143 95 143 42.5 136.6 42.5 136.6 37.3 143 37.3 143 13 148.6 13 148.6 37.2 158.7 37.2 158.7 42.4 148.6 42.4"
          />
          <polygon
            id="Shape"
            points="178.7 95 173.1 95 173.1 37.2 178.7 37.2"
          />
          <path
            d="M242,50.6 C236.5,44.6 229.9,41.2 221.6,41.2 C208,41.2 197.7,52.8 197.7,66 C197.7,79.2 207.8,91 221.5,91 C229.7,91 237.5,87 242.7,80.6 L242.7,88.2 C236.8,93.3 229.5,96.2 221.7,96.2 C204.6,96.2 192.1,82.8 192.1,66 C192.1,49.2 204.9,36 221.9,36 C229.4,36 236.3,38.2 242,43.1 L242,50.6 Z"
            id="Shape"
          />
        </g>
        <path
          d="M391,20 C391,24.5 387.5,28 383,28 C378.5,28 375,24.5 375,20 C375,15.7 378.5,12 383,12 C387.5,12 391,15.7 391,20 Z"
          id="Shape"
          fill="#00A37C"
        />
      </g>
    </g>
  </SVG>
)

export default withIconStyles(IconOnWhiteLogo)
