import {
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  zero,
  dotDecimal,
  equalSymbol,
} from './buttons'

export const NUMBER_PAD_ITEMS = [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  zero,
  dotDecimal,
  equalSymbol,
]

/**
import LeftIco from "../assets/keyboardButtons/left.svg";
import RightIco from "../assets/keyboardButtons/right.svg";
import DeleteIco from "../assets/keyboardButtons/delete.svg";
{ value: "\\div", label: "÷", data_cy: "div" }
{ value: ",", label: "," },
{ value: "left_move", label: <img src={LeftIco} width={10} alt="left" /> },
{ value: "right_move", label: <img src={RightIco} width={10} alt="right" /> },
{
  value: "Backspace",
  label: <img src={DeleteIco} width={10} alt="delete" />
},
{ value: "=", label: "=" }
 {
    handler: '/',
    labelcy: 'divide',
    value: 'divide',
    label: (
      <CustomImage src={Fraction} width={25} height={40} role="presentation" />
    ),
    command: 'cmd',
  },
 */
