const ESSAY_PLAIN_TEXT = 'essayPlainText'
const ESSAY_RICH_TEXT = 'essayRichText'
const FORMULA_ESSAY = 'formulaessay'
const HIGHLIGHT_IMAGE = 'highlightImage'
const UPLOAD_FILE = 'uploadFile'
const AUDIO_RESPONSE = 'audioResponse'

module.exports = [
  ESSAY_PLAIN_TEXT,
  ESSAY_RICH_TEXT,
  FORMULA_ESSAY,
  HIGHLIGHT_IMAGE,
  UPLOAD_FILE,
  AUDIO_RESPONSE,
]
