const ROUND_DOWN = 'roundDown'
const NONE = 'none'

module.exports = {
  ROUND_DOWN,
  NONE,
  roundDown: {
    value: ROUND_DOWN,
    label: 'Round down',
  },
  none: {
    value: NONE,
    label: 'None',
  },
}
