const NUMBER_RANGE = 'Number Range'
const NUMBER_SET = 'Number Set'
const NUMBER_SEQUENCE = 'Number Sequence'
const TEXT_SET = 'Text Set'
const TEXT_SEQUENCE = 'Text Sequence'
const FORMULA = 'Formula'

module.exports = {
  NUMBER_RANGE,
  NUMBER_SET,
  NUMBER_SEQUENCE,
  TEXT_SET,
  TEXT_SEQUENCE,
  FORMULA,
}
