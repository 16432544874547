import API from './utils/API'

const api = new API()
const prefix = '/dashboard'

/*
 * api for fetching dashboard details
 */
const getTeacherDashboardDetails = (filterType, pageSize) => {
  const params = new URLSearchParams()
  if (filterType) {
    params.append('filterType', filterType)
  }
  if (pageSize) {
    params.append('pageSize', pageSize)
  }
  return api
    .callApi({
      url: `${prefix}/teacher`,
      method: 'get',
      params,
    })
    .then((result) => result.data.result)
}

const toggleFavouriteClass = (payload) =>
  api
    .callApi({
      url: `${prefix}/toggle-favourite`,
      method: 'post',
      data: payload,
    })
    .then(({ data }) => data.result)

export default {
  getTeacherDashboardDetails,
  toggleFavouriteClass,
}
