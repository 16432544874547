export const fileUploadStatusTypes = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED',
}

export const UPLOAD_CANCELLED_BY_USER_MESSAGE = 'UPLOAD_CANCELLED_BY_USER'

export const views = {
  ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS:
    'ITEM_BANK_CREATE_AI_ITEMS_ATTACHMENTS',
  TEST_SELECT_DOC_UPLOAD: 'TEST_SELECT_DOC_UPLOAD',
  TEST_SELECT_QTI_UPLOAD: 'TEST_SELECT_QTI_UPLOAD',
  TEST_SELECT_WEBCT_UPLOAD: 'TEST_SELECT_WEBCT_UPLOAD',
}
