// legacy file for report apis
import reportsApi from './common/reports'

// standards mastery reports
import standardsProgressReportApi from './standardsMasteryReport/standardsProgressReport'

// adaptive test reports
import studentScaledScoreReportApi from './adaptiveTestReport/studentScaledScoreReport'

export default {
  // legacy report apis
  // TODO: organize into module-level files
  ...reportsApi,
  // standards mastery reports
  ...standardsProgressReportApi,
  // adaptive test reports
  ...studentScaledScoreReportApi,
}
