const MOVE_ITEM = 'moveItem'
const FREE_DRAW = 'freeDraw'
const DRAW_BREAKING_LINE = 'drawBreakingLine'
const DRAW_CURVE_LINE = 'drawCurveLine'
const DRAW_SQUARE = 'drawSquare'
const DRAW_SIMPLE_LINE = 'drawSimpleLine'
const DRAW_CIRCLE = 'drawСircle'
const DRAW_TRIANGLE = 'drawTriangle'
const DRAW_TEXT = 'drawText'
const DRAW_MATH = 'drawMath'
const DRAW_MEASURE_TOOL = 'drawMeasureTool'
const DRAW_RULER_TOOL = 'drawRulerTool'
const DRAW_PROTRACTOR = 'drawProtractor'
const SELECT_TOOL = 'selectTool'
const COPY = 'copy'
const PASTE = 'paste'
const CUT = 'cut'
const MOVE_BEHIND = 'moveBehind'
const MOVE_FORWARD = 'moveForward'
const COMMENT = 'commentTool'
const EDITING_TOOL = 'EditingTools'
const UNDO_TOOL = 'undoTool'
const REDO_TOOL = 'redoTool'
const DELETE_TOOL = 'deleteTool'

module.exports = {
  MOVE_ITEM,
  FREE_DRAW,
  DRAW_BREAKING_LINE,
  DRAW_CURVE_LINE,
  DRAW_SQUARE,
  DRAW_SIMPLE_LINE,
  DRAW_CIRCLE,
  DRAW_TRIANGLE,
  DRAW_TEXT,
  DRAW_MATH,
  DRAW_MEASURE_TOOL,
  SELECT_TOOL,
  COPY,
  PASTE,
  CUT,
  MOVE_BEHIND,
  MOVE_FORWARD,
  COMMENT,
  EDITING_TOOL,
  UNDO_TOOL,
  REDO_TOOL,
  DELETE_TOOL,
  DRAW_RULER_TOOL,
  DRAW_PROTRACTOR,
}
