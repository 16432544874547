import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { white, testTypeColor } from '@edulastic/colors'
import { TEST_TYPE_SURVEY } from '@edulastic/constants/const/testTypes'
import { testCategoryTypes } from '@edulastic/constants/const/test'

const testTypesWithModifiedIcon = [TEST_TYPE_SURVEY]

const testTypeToIconMap = {
  survey: 'SU',
}

const testTypeToFontSizeMap = {
  survey: '10px',
}

const TestTypeIcon = ({
  testType,
  toolTipTitle = '',
  toolTipPlacement = 'top',
  testCategory = '',
  isSectionAdaptiveTest = false,
}) => {
  let testTypePrefix = testTypesWithModifiedIcon.includes(testType)
    ? testTypeToIconMap[testType]
    : testType.split('')[0].toUpperCase()

  if (
    testCategory === testCategoryTypes.ADAPTIVE_SECTION_TEST ||
    isSectionAdaptiveTest
  ) {
    testTypePrefix = 'AD'
    testType = 'adaptive'
    toolTipTitle = 'Adaptive'
  }
  return (
    <Tooltip placement={toolTipPlacement} title={toolTipTitle}>
      <TypeIcon
        data-cy="testType"
        data-testid="testType"
        type={testType}
        count={testTypePrefix.length}
      >
        {testTypePrefix}
      </TypeIcon>
    </Tooltip>
  )
}

const TypeIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  width: ${({ count }) => (count === 1 ? 18 : 25)}px;
  height: ${({ count }) => (count === 1 ? 18 : 25)}px;
  max-width: ${({ count }) => (count === 1 ? 18 : 25)}px;
  background: ${(props) => testTypeColor[props.type]};
  color: ${white};
  border-radius: 50%;
  font-weight: 600;
  font-size: ${(props) =>
    testTypeToFontSizeMap[props.type] || props.theme.bodyFontSize};
  align-self: center;
  margin: 0px 10px;
`

export default TestTypeIcon
