import styled from 'styled-components'
import { title } from '@edulastic/colors'

export default styled.div`
  color: ${title};
  letter-spacing: 0px;
  text-transform: uppercase;
  & span {
    margin: 0px 8px;
  }
`
