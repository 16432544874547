import React from 'react'
import { EduButton } from '@edulastic/common'
import { IconAIFileAttachment } from '@edulastic/icons'
import {
  StyledUploaderContainer,
  StyledFlexItemContainer,
  StyledAIDocumentTextContainer,
  StyledUploadAttachmentText,
  StyledSupportedFilesText,
  StyledButtonText,
} from '../styled-components'

function AIItemsUploader({ fileInputClickHandler }) {
  return (
    <StyledUploaderContainer>
      <StyledFlexItemContainer flexGrow={1} marginLeft="10px">
        <IconAIFileAttachment />
      </StyledFlexItemContainer>
      <StyledAIDocumentTextContainer flexGrow={2}>
        <StyledUploadAttachmentText>
          Upload or Drag & Drop Document
        </StyledUploadAttachmentText>
        <StyledSupportedFilesText>
          (.docx, PDF format, Images. Max size 5MB)
        </StyledSupportedFilesText>
      </StyledAIDocumentTextContainer>
      <StyledFlexItemContainer flexGrow={0} marginRight="15px">
        <EduButton
          data-cy="aiUploadDocButton"
          width="100px"
          height="32px"
          onClick={fileInputClickHandler}
        >
          <StyledButtonText>UPLOAD</StyledButtonText>
        </EduButton>
      </StyledFlexItemContainer>
    </StyledUploaderContainer>
  )
}

export default AIItemsUploader
