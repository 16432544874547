import qs from 'qs'

const apiUri = process.env.REACT_APP_API_URI

export const getApiUri = () => {
  if (apiUri) {
    return apiUri
  }
  const currentOrigin = window.location.origin
  const url = new URL(currentOrigin)
  return `${url.origin}/api/`
}

export const parseUrlParams = (text, opts = {}) =>
  qs.parse(text, {
    ignoreQueryPrefix: true,
    // NOTE: setting arrayLimit to sufficiently large value.
    // if array has >arrayLimit(default 20) elements, qs will convert it into object.
    // ref: https://github.com/ljharb/qs/blob/3c8a6f5a1e8d2c4772fd733c7ca1a28e92498fd7/README.md?plain=1#L273-L282
    arrayLimit: 200,
    ...opts,
  })
