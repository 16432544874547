module.exports = {
  studentAssignmentConstants: {
    assignmentStatus: {
      GRADED: 'GRADED',
      GRADE_HELD: 'GRADE_HELD',
      NOT_GRADED: 'NOT_GRADED',
    },
  },
  authorAssignmentConstants: {
    assignmentStatus: {
      NOT_OPEN: 'NOT OPEN',
      IN_PROGRESS: 'IN PROGRESS',
      IN_PROGRESS_PAUSED: 'IN PROGRESS (PAUSED)',
      IN_GRADING: 'IN GRADING',
      IN_GRADING_PAUSED: 'IN GRADING (PAUSED)',
      NOT_GRADED: 'NOT GRADED',
      NOT_GRADED_PAUSED: 'NOT GRADED',
      GRADES_HELD: 'GRADES HELD',
      GRADES_HELD_PAUSED: 'GRADES HELD (PAUSED)',
      SUBMITTED: 'SUBMITTED',
      SUBMITTED_PAUSED: 'SUBMITTED (PAUSED)',
      NOT_STARTED: 'NOT STARTED',
      NOT_STARTED_PAUSED: 'NOT STARTED (PAUSED)',
      DONE: 'DONE',
    },
  },
  status: {
    SUBMITTED: 'submitted',
    IN_PROGRESS: 'inProgress',
  },
  gradingStatus: {
    GRADED: 'GRADED',
    IN_GRADING: 'IN GRADING',
  },
}
