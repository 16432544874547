import React from 'react'

import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconRetryCircle = ({ fillColor = '#1AB395', ...props }) => (
  <SVG
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.76326 2.23341C8.79659 1.26675 7.46992 0.666748 5.99659 0.666748C3.04992 0.666748 0.669922 3.05341 0.669922 6.00008C0.669922 8.94675 3.04992 11.3334 5.99659 11.3334C8.48326 11.3334 10.5566 9.63341 11.1499 7.33341H9.76326C9.21659 8.88675 7.73659 10.0001 5.99659 10.0001C3.78992 10.0001 1.99659 8.20675 1.99659 6.00008C1.99659 3.79341 3.78992 2.00008 5.99659 2.00008C7.10326 2.00008 8.08992 2.46008 8.80992 3.18675L6.66326 5.33341H11.3299V0.666748L9.76326 2.23341Z"
      fill={fillColor}
    />
  </SVG>
)

export default withIconStyles(IconRetryCircle)
