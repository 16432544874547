import React from 'react'
import Divider from 'antd/lib/divider'
import { IconDeskTopMonitor, IconGdrive } from '@edulastic/icons'
import { EduButton, FlexContainer } from '@edulastic/common'
import {
  StyledSupportedFilesText,
  StyledUploadIcon,
  StyledDocUploadTitle,
  StyledDividerContainer,
  StyledORText,
} from '../styled-components'
import { GooglePicker } from '../../../../../../src/vendors/google'

function DocumentUploader({
  fileInputClickHandler,
  onSelect,
  onAuthFailed,
  allowedFileTypes,
}) {
  return (
    <FlexContainer flexDirection="column" alignItems="center">
      <StyledUploadIcon />
      <StyledDocUploadTitle>
        Drag and drop your worksheet/ question bank/ document
      </StyledDocUploadTitle>
      <StyledDividerContainer>
        <Divider />
        <StyledORText>OR</StyledORText>
        <Divider />
      </StyledDividerContainer>
      <FlexContainer>
        <EduButton
          data-cy="uploadFromDeviceButton"
          style={{ borderRadius: '8px' }}
          onClick={fileInputClickHandler}
        >
          <IconDeskTopMonitor />
          Upload From Device
        </EduButton>
        <GooglePicker
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onChange={onSelect}
          onAuthFailed={onAuthFailed}
          mimeTypes={allowedFileTypes}
        >
          <EduButton
            data-cy="uploadFromDriveButton"
            style={{ borderRadius: '8px', marginLeft: '15px' }}
          >
            <IconGdrive />
            Import From Drive
          </EduButton>
        </GooglePicker>
      </FlexContainer>
      <StyledSupportedFilesText fontSize="14px" marginTop="20px">
        ( Supports DOCX, PDF, JPG, PNG in English language. Max file size: 10MB
        )
      </StyledSupportedFilesText>
    </FlexContainer>
  )
}

export default DocumentUploader
